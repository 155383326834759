import { PropsWithChildren } from 'react';

import { Box, Icon, Paragraph, Logo } from '@hl-portals/ui';

type NumeratedItemProps = {
  index: number;
};

const NumeratedItem = ({
  index,
  children,
}: PropsWithChildren<NumeratedItemProps>) => (
  <Box alignItems="flex-start" gap="4px">
    <Paragraph variant="text-micro" color="gray500">
      {index}
    </Paragraph>
    <Paragraph variant="text-small" color="gray500">
      {children}
    </Paragraph>
  </Box>
);

export const Footer = () => (
  <><Box
    flex="1"
    gap={{ xs: '0', sm: '8px' }}
    flexDirection={{ xs: 'column', sm: 'row' }}
    flexWrap="wrap"
    justifyContent="center"
    alignItems="center"
  >
    <Box alignItems="center" gap="4px">
      <Paragraph variant="text-xsmall" color="gray700">
        Powered by
      </Paragraph>
      <Logo width="97px" variant="horizontal" />
    </Box>
  </Box>
  <Box
    mx={{ xs: '16px', md: '80px' }}
    flexDirection="column"
    borderTop="1px solid #DBDFE6"
    pt="24px"
    pb="120px"
  >
      <Box
        flex="1"
        mb="32px"
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent={{ xs: 'flex-start', md: 'space-between' }}
        alignItems={{ xs: 'flex-start', md: 'center' }}
        gap={{ xs: '20px', md: '0' }}
      >
        <Box gap="24px" alignItems="center">
          <Box
            width="32px"
            as="img"
            src="/images/lead-capture/equal-housing-logo.svg" />
          <Box width="80px" as="img" src="/images/lead-capture/bbb-logo.svg" />
          <Paragraph variant="text-small" color="gray500">
            HomeLight has an A+ rating with the Better Business Bureau
          </Paragraph>
        </Box>

        <Box gap="16px" alignItems="center">
          <a href="https://twitter.com/gohomelight" target="_blank">
            <Icon type="twitter" />
          </a>
          <a href="https://www.facebook.com/gohomelight/" target="_blank">
            <Icon type="facebookSlim" />
          </a>
          <a href="https://www.instagram.com/gohomelight/" target="_blank">
            <Icon type="instagram" />
          </a>
          <a href="https://www.youtube.com/@HomeLightRE" target="_blank">
            <Icon type="youtube" />
          </a>
          <a href="https://www.pinterest.com/homelightapp/" target="_blank">
            <Icon type="pinterest" />
          </a>
        </Box>
      </Box>

      <Box flexDirection="column" px={{ xs: '16px', md: '160px' }} gap="24px">
        <Box flexDirection="column">
          <NumeratedItem index={1}>
            A home sale contingency is when a buyer's offer is dependent on
            selling their current home first. Sellers often dislike offers with
            home sale contingencies because they introduce uncertainty and
            potential delays into the selling process.
          </NumeratedItem>
          <NumeratedItem index={2}>
            Fees subject to change; minimums apply.
          </NumeratedItem>
          <NumeratedItem index={3}>
            Reported by agents that work with HomeLight
          </NumeratedItem>
          <NumeratedItem index={4}>
            The final Equity Unlock Amount is define based on agent’s estimation
            of the home’s value, current market conditions, projected market risk,
            borrower financials, outstanding loans, and more.
          </NumeratedItem>
        </Box>

        <Paragraph variant="text-small" color="gray500" textAlign="center">
          Our Commitment to Accessibility: HomeLight is committed to making our
          website accessible and user friendly to all. While we are constantly
          working to improve, we welcome your feedback and accommodation requests.
          If you are having difficulty accessing or navigating our website, or if
          you have any suggestions to improve accessibility, please email our team
          or contact us.
        </Paragraph>
        <Paragraph variant="text-small" color="gray500" textAlign="center">
          © HomeLight, Inc., 100 California, Suite 800, San Francisco, CA 94015
          <br />
          <br />
          Terms of Service Privacy Policy
          <br />
          <br />
          HomeLight Home Loans, Inc. NMLS ID#1529229, Equal Housing Lender.
          <br />
          <br />
          1375 N. Scottsdale Rd., # 110, Scottsdale, AZ 85257 Telephone
          844-882-3283
          <br />
          <br />
          All rights reserved
        </Paragraph>
      </Box>
    </Box></>
);
