import { colors } from '@homelight/particle-tokens';
import * as Sentry from '@sentry/nextjs';
import styled from 'styled-components';
import { z } from 'zod';

import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';

import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';

import {
  Accordion,
  Box,
  Button,
  Collapsible,
  FadeIn,
  Form,
  HorizontalTimeline,
  Icon,
  IconTypeProp,
  Inputs,
  Logo,
  Paragraph,
  SimpleLink,
  YouTubePlayer,
  useForm,
} from '@hl-portals/ui';

import { capitalizeWord, config } from '@hl-portals/helpers';

import { useModal, useScreenSize } from '@hl-portals/hooks';

import { AgentPageProps, AgentProfile } from '../../metadata/types';
import Head from 'next/head';

type AgentPageContextState = {
  data: AgentProfile;
  openFormModal: () => void;
  hideAboutMe?: boolean;
};

const AgentPageContext = createContext<AgentPageContextState | null>(null);

const LOCAL_STORAGE_KEY = 'visitor_id';

const useVisitor = () => {
  const BASE_URL = config.hapiHost + '/api/session-service';
  const VISITOR_URL = BASE_URL + '/visitors/find-or-create';
  const SESSION_URL = BASE_URL + '/sessions/update-or-create';

  const fetchVisitor = async () => {
    try {
      const res = await fetch(VISITOR_URL);
      const { visitor_id } = await res.json();

      localStorage.setItem(LOCAL_STORAGE_KEY, visitor_id);
      return visitor_id;
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const createSession = async (visitorId: number, entryPath: string) => {
    try {
      await fetch(
        SESSION_URL + `?entry_path=${entryPath}&visitor_id=${visitorId}`,
        {
          method: 'POST',
        }
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const init = async () => {
    const visitorId =
      localStorage.getItem(LOCAL_STORAGE_KEY) || (await fetchVisitor());
    const entryPath = window.location.href;

    await createSession(+visitorId, entryPath);
  };

  useEffect(() => {
    init();
  }, []);
};

const sendUserEvent = (agentSlug: string, eventAction: string) => {
  fetch(
    config.hapiHost +
      '/api/lead-data-service/client-page/buy-before-you-sell/user-event',
    {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
      body: JSON.stringify({
        event_action: eventAction,
        agent_slug: agentSlug,
      }),
    }
  );
};

const BlurredBall = styled(Box)`
  position: absolute;
  width: 436px;
  height: 436px;
  z-index: 0;
  background: #fff6eb;
  filter: blur(122px);
`;

const AgentImage = styled(Box)<{ src: string }>`
  border-radius: 8px;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
`;

const BrokerageLogo = styled.img`
  max-width: 134px;
  max-height: 134px;
  position: absolute;
  right: 118px;
  border-radius: 8px;
`;

type AgentInfoSectionProps = {
  fullName: string;
  brokerImageUrl?: string;
  profileImageUrl?: string;
  officeName?: string;
};

const AgentInfoSection = ({
  fullName,
  brokerImageUrl,
  profileImageUrl,
  officeName,
}: AgentInfoSectionProps) => {
  const hasImageAndBrokerImage = profileImageUrl && brokerImageUrl;
  const hasImageOrBrokerImage = profileImageUrl || brokerImageUrl;

  return (
    <Box
      mt="72px"
      flexDirection="column"
      alignItems="center"
      alignSelf="center"
      gap="16px"
    >
      {hasImageOrBrokerImage && (
        <Box
          gap={hasImageAndBrokerImage ? '10px' : '0'}
          pl={hasImageAndBrokerImage ? '107px' : '0'}
          alignItems="center"
          position="relative"
        >
          {brokerImageUrl && <BrokerageLogo src={brokerImageUrl} />}
          {hasImageAndBrokerImage && (
            <Box width="1px" height="97px" bgcolor="#DBDFE6" />
          )}
          {profileImageUrl && (
            <AgentImage src={profileImageUrl} width="97px" height="97px" />
          )}
        </Box>
      )}
      <Box flexDirection="column" gap="4px" alignItems="center">
        <Paragraph variant="heading-4">{fullName}</Paragraph>
        {officeName && <Paragraph>Agent with {officeName}</Paragraph>}
      </Box>
    </Box>
  );
};

const Hero = () => {
  const { data, openFormModal } = useContext(AgentPageContext);
  const {
    full_name,
    profile_image_url,
    office_name_to_display,
    broker_image_url,
  } = data;

  return (
    <Box
      pt={{ xs: '40px', sm: '80px' }}
      pb="104px"
      px="16px"
      bgcolor="linear-gradient(180deg, #FCFCFD 0%, #EDEEF8 50%, #ECD0DB 100%)"
      position="relative"
      flexDirection="column"
      alignItems="center"
    >
      <Box
        flexDirection="column"
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        zIndex="1"
      >
        <Paragraph
          variant="heading-3"
          mb={{ xs: '12px', sm: '32px' }}
          fontWeight="regular"
        >
          Buy Before You Sell
        </Paragraph>
        <Paragraph
          maxWidth="844px"
          mb="48px"
          variant="heading-1"
          textAlign={{ xs: 'left', sm: 'center' }}
        >
          Move into your new home now — and sell your current home with peace of
          mind.
        </Paragraph>
        <Button
          width="161px"
          size="large"
          color="custom"
          onClick={openFormModal}
          alignSelf="center"
        >
          Start here
        </Button>
        <AgentInfoSection
          fullName={full_name}
          profileImageUrl={profile_image_url}
          brokerImageUrl={broker_image_url}
          officeName={office_name_to_display}
        />
      </Box>

      <BlurredBall top="103px" left="30px" />
      <BlurredBall top="521px" left="466px" />
    </Box>
  );
};

const SectionTitle = ({ children }: PropsWithChildren) => {
  const { isMobile } = useScreenSize();

  return (
    <Paragraph
      textTransform="uppercase"
      textAlign="center"
      letterSpacing="0.1em"
      fontWeight="700"
      mb={{ xs: '24px', sm: '40px' }}
      variant={isMobile ? 'text-xsmall' : 'text'}
    >
      {children}
    </Paragraph>
  );
};

const HowItWorks = () => {
  const router = useRouter();
  const { openFormModal, hideAboutMe } = useContext(AgentPageContext);

  const agentSlug = router.query.agentSlug as string;
  const CLICKED_KEY = 'clicked';

  const onYoutubePlay = () => {
    if (localStorage.getItem(CLICKED_KEY) !== 'true') {
      sendUserEvent(agentSlug, 'bbys_howto_video_viewed');
      localStorage.setItem(CLICKED_KEY, 'true');
    }
  };

  const onYoutubeUnmount = () => {
    localStorage.removeItem(CLICKED_KEY);
  };

  return (
    <Box
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      padding={{ xs: '56px 16px 40px', md: '120px 300px 80px' }}
      bgcolor="white"
      borderRadius={{ xs: '100px 100px 0px 0px', md: '410px 410px 0px 0px' }}
      position="relative"
      zIndex="1"
    >
      <SectionTitle>How it works</SectionTitle>

      <Box mb={{ xs: '24px', md: '40px' }}>
        <HorizontalTimeline
          items={[
            {
              title: 'Unlock equity from your home with ease',
              description:
                'We’ll evaluate your property for the program and let you know how much of your equity you can unlock for the purchase of your new home.',
            },
            {
              title: 'Buy your dream home with confidence',
              description:
                'Make a strong offer on your new home without a home sale contingency - and avoid moving twice.',
            },
            {
              title: 'Sell your current home with peace of mind',
              description:
                "We'll list your vacant home on the market to attract the strongest offers possible.",
            },
          ]}
        />
      </Box>

      <Box width="100%" maxWidth="900px" height="500px">
        <YouTubePlayer
          videoId="Kg9IaIHZ6Mo"
          containerProps={{ my: { xs: '24px', md: '80px' } }}
          onPlay={onYoutubePlay}
          onUnmount={onYoutubeUnmount}
        />
      </Box>

      {!hideAboutMe && (
        <SimpleLink color={colors.darkBlue} underlined href="#faq" mb="24px">
          See frequently asked questions
        </SimpleLink>
      )}

      <Button size="large" color="custom" onClick={openFormModal}>
        Start here
      </Button>
    </Box>
  );
};

const InfoSection = ({
  children,
  title,
}: PropsWithChildren<{ title: string }>) => (
  <Box flexDirection="column">
    <Paragraph variant="text-small" color="coolGray1" mb="24px">
      {title}
    </Paragraph>
    {children}
  </Box>
);

type Item = {
  title: string;
  text?: string;
  icon?: IconTypeProp;
};

type ItemsProps = {
  items: Item[];
};

const Items = ({ items }: ItemsProps) => (
  <Collapsible
    flexDirection="column"
    gap="24px"
    items={items}
    render={(item: Item) => {
      const { title, text, icon } = item;

      const iconSize =
        icon && icon === 'trophy'
          ? {
              size: 32,
              flex: '0 0 32px',
              width: '32px',
              heigh: '32px',
            }
          : {
              size: 24,
              flex: '0 0 24px',
              width: '24px',
              heigh: '24px',
            };

      return (
        <Box gap="20px" alignItems="flex-start" key={title}>
          {icon && <Icon type={icon} {...iconSize} />}
          <Box flexDirection="column" gap="8px">
            <Paragraph fontWeight="700">{title}</Paragraph>
            {text && <Paragraph>{text}</Paragraph>}
          </Box>
        </Box>
      );
    }}
  />
);

const BiographyText = styled(Paragraph)<{ isCollapsed?: boolean }>`
  display: -webkit-box;
  line-height: 180%;
  -webkit-box-orient: vertical;
  transition: all 300ms ease-in-out;
  white-space: pre-wrap;
  overflow: hidden;

  ${({ isCollapsed }) =>
    isCollapsed &&
    `
      -webkit-line-clamp: 5;
  `}
`;

const Biography = ({ biography }: { biography: string }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const paragraphRef = useRef<HTMLParagraphElement>(null);
  const isTruncated = 144 < paragraphRef.current?.scrollHeight;

  return (
    <>
      <Box overflow="hidden">
        <BiographyText isCollapsed={isCollapsed} ref={paragraphRef}>
          {biography}
        </BiographyText>
      </Box>
      {isTruncated && (
        <Paragraph
          underline
          cursor="pointer"
          onClick={() => setIsCollapsed((prev) => !prev)}
        >
          See {isCollapsed ? 'more' : 'less'}
        </Paragraph>
      )}
    </>
  );
};

const AboutMe = () => {
  const { isMobile } = useScreenSize();
  const { data } = useContext(AgentPageContext);

  const {
    full_name,
    profile_image_url,
    biography,
    agent_certifications,
    agent_awards,
    languages,
  } = data;

  return (
    <Box bgcolor="#F5F6F9" justifyContent="center">
      <Box
        width="100%"
        maxWidth="841px"
        py="80px"
        px="16px"
        flexDirection="column"
        alignItems="stretch"
      >
        <SectionTitle>About me</SectionTitle>

        <Box
          gap={biography ? '56px' : '24px'}
          mb="40px"
          mt={{ xs: '38px', sm: '0' }}
          alignItems={biography ? 'flex-start' : 'center'}
        >
          {!isMobile && profile_image_url && (
            <AgentImage
              width="188px"
              height="188px"
              flex="0 0 188px"
              src={profile_image_url}
              border="4px solid #273653"
            />
          )}

          <Box flexDirection="column" gap="16px">
            <Paragraph variant="heading-4">Hi! I’m {full_name}</Paragraph>
            {biography && <Biography biography={biography} />}
          </Box>
        </Box>

        <Box flexDirection="column" gap="24px">
          {!!agent_awards?.length && (
            <InfoSection title="Achievements">
              <Items
                items={agent_awards.map((award) => ({
                  icon: 'trophy',
                  title: award.text,
                }))}
              />
            </InfoSection>
          )}
          {!!agent_certifications?.length && (
            <InfoSection title="Certifications">
              <Items
                items={agent_certifications.map((certification) => ({
                  icon: 'medal',
                  title: certification.text,
                }))}
              />
            </InfoSection>
          )}
          {!!languages?.length && (
            <InfoSection title="Languages">
              <Box gap="24px">
                {languages.map(({ name }) => (
                  <Paragraph key={name}>
                    {capitalizeWord(name.toLowerCase())}
                  </Paragraph>
                ))}
              </Box>
            </InfoSection>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const IndentedText = styled(Paragraph)`
  margin-left: 40px;
  padding-left: 16px;
  position: relative;

  &::before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #273653;
    position: absolute;
    top: calc(50% - 2px);
    left: 0;
  }
`;

const FAQ = () => {
  const getProps = (label: string) => ({
    icon: 'angleRight' as IconTypeProp,
    iconPosition: 'right' as any,
    borderBottom: `1px solid ${colors.coolGray4} `,
    paddingBottom: '16px',
    label: <Paragraph variant="text-bold">{label}</Paragraph>,
  });

  return (
    <Box justifyContent="center" bgcolor="white" id="faq">
      <Box
        width="100%"
        maxWidth="1280px"
        py="80px"
        px="20px"
        flexDirection="column"
      >
        <Paragraph variant="heading-1" mb="32px">
          FAQs
        </Paragraph>

        <Box flexDirection="column" gap="16px">
          <Accordion {...getProps('How much does it cost?')}>
            <Box flexDirection="column">
              <Paragraph mb="12px">The cost of the program is:</Paragraph>
              <IndentedText>
                2.4%* of the home sale if you choose to use an outside lender
                and closing company.
              </IndentedText>
              <IndentedText>
                1.7%* of the home sale if you use our lending and closing
                services.
              </IndentedText>
            </Box>
          </Accordion>

          <Accordion
            {...getProps(
              'How do you determine how much equity I can unlock from my current home?'
            )}
          >
            <Paragraph>
              The amount of equity you can unlock is determined using
              HomeLight’s proprietary algorithm, which takes into consideration
              an estimation of the home’s value, current market conditions,
              projected market risk, borrower financials, outstanding loans, and
              more. You’ll be able to unlock a portion of the overall equity you
              have in your home upfront, prior to selling the home — and will
              receive the remainder of your equity after the home sells, less
              program fees.
            </Paragraph>
          </Accordion>

          <Accordion {...getProps('What if my home is already listed?')}>
            <Paragraph>
              Not a problem! We consider all properties for the program
              regardless of days on market, and will adjust the Equity Unlock
              Amount according to your property's risk profile.
            </Paragraph>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};

const Footer = () => (
  <>
    <Box
      width="100%"
      height="50px"
      bgcolor={colors.white}
      alignItems="center"
      justifyContent="center"
    >
      <Paragraph color="coolGray2" variant="text-xsmall">
        Please note that fees are subject to change and may vary based on market
        conditions. Minimums may apply.
      </Paragraph>
    </Box>
    <Box
      width="100%"
      height="50px"
      bgcolor={colors.darkBlue}
      alignItems="center"
      justifyContent="center"
    >
      <Paragraph color="white" mr="12px">
        Powered by{' '}
      </Paragraph>
      <Logo variant="horizontalWhite" width="100px" />
    </Box>
  </>
);

const NAME_ERROR = 'Please enter your full name.';
const EMAIL_ERROR = 'Please enter your email.';
const PHONE_ERROR = 'Please enter your phone number.';
const ADDRESS_ERROR = 'Please enter the address of the home that will be sold.';

const schema = z.object({
  name: z
    .string({ required_error: NAME_ERROR })
    .min(1, { message: NAME_ERROR }),
  email: z
    .string({ required_error: EMAIL_ERROR })
    .min(1, { message: EMAIL_ERROR })
    .email(),
  phone: z
    .string({ required_error: PHONE_ERROR })
    .min(1, { message: PHONE_ERROR }),
  fullAddress: z
    .string({ required_error: ADDRESS_ERROR })
    .min(1, { message: ADDRESS_ERROR }),
  address: z.string().optional(),
  propertyCity: z.string().optional(),
  propertyState: z.string().optional(),
  propertyPostalCode: z.string().optional(),
});

type FormData = z.infer<typeof schema>;

type Payload = {
  name: string;
  email: string;
  phone: string;
  address: string;
  city_display?: string;
  state_code?: string;
  zip_code?: string;
  visitor_id: number;
  agent_slug: string;
};

const FormModal = () => {
  const [showSuccess, setShowSuccess] = useState(false);

  const { closeModal } = useModal();
  const router = useRouter();

  const methods = useForm({ schema });
  const { setValue } = methods;

  const { isLoading, mutate } = useMutation(
    async (payload: Payload) => {
      const URL =
        config.hapiHost +
        '/api/lead-data-service/client-page/buy-before-you-sell/event';

      await fetch(URL, {
        method: 'post',
        headers: new Headers({
          'Content-Type': 'application/json',
        }),
        body: JSON.stringify(payload),
      });
    },
    {
      onError: (error) => {
        Sentry.captureException(error);
        toast('There was an error submitting your information', {
          type: 'error',
          autoClose: 4000,
        });
      },
      onSuccess: () => {
        setShowSuccess(true);
      },
    }
  );

  const onSubmit = async ({
    name,
    email,
    phone,
    fullAddress,
    address,
    propertyCity,
    propertyState,
    propertyPostalCode,
  }: FormData) => {
    const visitorId = localStorage.getItem(LOCAL_STORAGE_KEY);
    const agentSlug = router.query.agentSlug as string;

    mutate({
      name,
      email,
      phone,
      address: address || fullAddress,
      city_display: propertyCity,
      state_code: propertyState,
      zip_code: propertyPostalCode,
      visitor_id: +visitorId,
      agent_slug: agentSlug,
    });
  };

  const boxProps = showSuccess
    ? {
        width: { xs: '100%', sm: '480px' },
        margin: '0 auto',
        p: { xs: '40px 4px 40px 4px', sm: '40px 16px 24px 16px' },
      }
    : {
        width: { xs: '100%', sm: '568px' },
        margin: '0 auto',
        p: { xs: '40px 4px 40px 4px', sm: '40px 24px 24px 24px' },
        flexDirection: 'column',
      };

  return (
    <Box style={{ transition: 'all 300ms ease-in-out' }} {...boxProps}>
      {showSuccess ? (
        <FadeIn flexDirection="column" alignItems="center" gap="16px">
          <Icon type="checkCircleLight" fill="confirm" size={42} />
          <Paragraph
            textAlign={{ xs: 'center', sm: 'left' }}
            variant="heading-3"
          >
            We received your submission
          </Paragraph>
          <Paragraph
            textAlign={{ xs: 'center', sm: 'left' }}
            mb="8px"
            lineHeight="28.8px"
            fontWeight="400"
          >
            We will be in touch with additional information about the Buy Before
            You Sell program.
          </Paragraph>
          <Button
            width="120px"
            size="large"
            color="custom"
            onClick={() => closeModal()}
          >
            Ok
          </Button>
        </FadeIn>
      ) : (
        <>
          <Paragraph variant="heading-3" mb="8px">
            Get started with Buy Before You Sell
          </Paragraph>
          <Paragraph mb="24px">
            Find out how much equity we can unlock for you to buy your next
            home.
          </Paragraph>

          <Form methods={methods} onSubmit={onSubmit}>
            <Box flexDirection="column" gap="24px" mb="32px">
              <Inputs.Text
                name="name"
                label="Name"
                placeholder="Enter your full name"
                autofocus
              />
              <Inputs.Email
                name="email"
                label="Email"
                placeholder="Enter your email"
              />
              <Inputs.Phone
                name="phone"
                label="Phone"
                placeholder="Enter your phone number"
              />
              <Inputs.Address
                name="fullAddress"
                label="Address (The departing residence that will be sold)"
                placeholder="Enter the address"
                onEnter={({
                  hl_full_address,
                  city,
                  state_or_province,
                  postal_code,
                }) => {
                  const fullAddress = `${hl_full_address}, ${city}, ${state_or_province} ${postal_code}`;

                  setValue('address', hl_full_address);
                  setValue('fullAddress', fullAddress);
                  setValue('propertyCity', city);
                  setValue('propertyState', state_or_province);
                  setValue('propertyPostalCode', postal_code);
                }}
              />
            </Box>

            <Button
              type="submit"
              size="large"
              width="100%"
              color="custom"
              isLoading={isLoading}
            >
              Submit
            </Button>
          </Form>
        </>
      )}
    </Box>
  );
};

const AgentBanner = () => (
  <Box bgcolor="#273653" justifyContent="center">
    <Box
      width="100%"
      p={{ xs: '16px', md: '12px 80px' }}
      justifyContent="space-between"
      alignItems="center"
      gap="8px"
    >
      <Paragraph variant="text-small" color="white">
        To edit or add your personal information go to Edit profile. This banner
        is not visible for clients.
      </Paragraph>
      <Button href="/settings" target="_blank" flex="0 0 90px">
        Edit profile
      </Button>
    </Box>
  </Box>
);

export const AgentPageV1 = ({ data }: AgentPageProps) => {
  const { openModal } = useModal();
  const session = useSession();
  const router = useRouter();

  const agentSlug = router.query.agentSlug as string;

  useVisitor();

  const {
    profile_image_url,
    biography,
    agent_certifications,
    agent_awards,
    state_code,
    languages,
  } = data;

  const hideAboutMe =
    !profile_image_url &&
    !biography &&
    !state_code &&
    !agent_certifications.length &&
    !agent_awards.length &&
    !languages.length;

  const openFormModal = () => {
    sendUserEvent(agentSlug, 'start_here_click');
    openModal(<FormModal />);
  };

  return (
    <AgentPageContext.Provider value={{ data, openFormModal, hideAboutMe }}>
      <Head>
        <meta property="og:image" content="/images/lead-capture/bbys-link-preview.png"/>
        <meta property="twitter:image" content="/images/lead-capture/twitter-bbys-link-preview.png"/>
        <meta property="og:title" content="Buy Before You Sell"/>
        <meta property="og:type" content="website"/>
      </Head>
      <FadeIn flexDirection="column">
        {session.data && <AgentBanner />}
        <Box
          flexDirection="column"
          width="100%"
          height="100%"
          overflowX="hidden"
        >
          <Hero />
          <HowItWorks />
          {!hideAboutMe && <AboutMe />}
          <FAQ />
          <Footer />
        </Box>
      </FadeIn>
    </AgentPageContext.Provider>
  );
};
