import { Box, BoxTypes } from '@hl-portals/ui';

export const CheckCircle = (props: BoxTypes) => (
  <Box {...props}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 12C22 17.5229 17.5229 22 12 22C6.47714 22 2 17.5229 2 12C2 6.47714 6.47714 2 12 2C17.5229 2 22 6.47714 22 12Z"
        fill="#E9F6FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8428 17.2949L18.2621 9.87556C18.5141 9.62362 18.5141 9.21511 18.2621 8.96318L17.3497 8.0508C17.0978 7.79882 16.6893 7.79882 16.4373 8.0508L10.3866 14.1015L7.5616 11.2766C7.30967 11.0246 6.90116 11.0246 6.64918 11.2766L5.7368 12.1889C5.48487 12.4409 5.48487 12.8494 5.7368 13.1013L9.93035 17.2949C10.1823 17.5468 10.5908 17.5468 10.8428 17.2949Z"
        fill="#46B6FF"
      />
    </svg>
  </Box>
);
