import { z } from 'zod';

const ERRORS = {
  ADDRESS: 'Please enter the address of the home that will be sold',
  VALUE: "Plase enter the your home's worth",
  NAME: 'Please provide your first and last name',
  EMAIL: 'Please provide a valid email, you will not receive spam',
};

export const schema = z.object({
  uuid: z.string(),
  fullAddress: z
    .string({ required_error: ERRORS.ADDRESS })
    .min(1, { message: ERRORS.ADDRESS }),
  address: z.string().optional(),
  state: z.string().optional(),
  city: z.string().optional(),
  postalCode: z.string().optional(),
  unit: z.string().optional(),
  value: z.string().min(1, { message: ERRORS.VALUE }),
  mortgage: z.string().optional(),
  name: z.string().min(1, { message: ERRORS.NAME }),
  email: z.string().email({ message: ERRORS.EMAIL }),
});

type FormData = z.infer<typeof schema>;
export type EUCForm = Required<Omit<FormData, 'unit'>> & {
  unit?: string;
};
