import { PropsWithChildren } from 'react';

import { Box } from '@hl-portals/ui';

export const StickyContainer = ({ children }: PropsWithChildren) => (
  <Box
    p="16px 16px 28px 16px"
    position="sticky"
    bottom="0"
    left="0"
    right="0"
    borderTop="1px solid #EEF0F6"
    bgcolor="white"
    zIndex="1"
  >
    {children}
  </Box>
);
