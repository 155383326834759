import { colors } from '@homelight/particle-tokens';

import { useContext } from 'react';

import {
  Accordion,
  Box,
  Footnote,
  IconTypeProp,
  IndentedText,
  Paragraph,
} from '@hl-portals/ui';

import { usePublicEvent } from '@hl-portals/hooks';

import { AgentPageContext } from '../../../../contexts/lead-capture/v2';

export const FAQ = () => {
  const { slug, uuid } = useContext(AgentPageContext);
  const { recordPublicEvent } = usePublicEvent();

  const getProps = (label: string, isOpen = false) => ({
    icon: 'angleRight' as IconTypeProp,
    iconPosition: 'right' as any,
    borderBottom: `1px solid ${colors.coolGray4} `,
    paddingBottom: '16px',
    label: (
      <Paragraph variant="text-bold" color="bbysBlue">
        {label}
      </Paragraph>
    ),
    onOpen: () => {
      recordPublicEvent({
        event_action: 'faq-open',
        source: 'bbys_self_service_express_approval',
        event_payload: {
          faq_question_answered: label,
          timestamp: new Date().toISOString(),
          agent_slug: slug,
          uuid,
        },
      });
    },
    isOpen,
  });

  const indentProps = {
    indentation: '8px',
    color: 'bbysBlue',
    bulletColor: 'bbysBlue',
  } as const;

  return (
    <Box justifyContent="center" px={{ xs: '16px', md: '80px' }} mb="80px">
      <Box width="100%" maxWidth="1280px" flexDirection="column">
        <Paragraph variant="heading-1" mb="32px" color="bbysBlue">
          FAQs
        </Paragraph>

        <Box flexDirection="column" gap="16px">
          <Accordion {...getProps('How much does the program cost?')}>
            <Box flexDirection="column">
              <Paragraph pt="2px" mb="12px" color="bbysBlue">
                It’s free to get started and see how much equity you can unlock.
                If you decide to transact using the program, there is a flat fee
                of{' '}
                <Footnote note="2" color="bbysBlue">
                  2.4%
                </Footnote>{' '}
                of the departing residence home sale.
              </Paragraph>
              <Paragraph color="bbysBlue">
                Thanks to the structure of the program, you might be able to
                save up to 1-3% of the purchase of your new home with a
                non-contingent offer and sell your existing home for up to 10%{' '}
                <Footnote note="3" color="bbysBlue">
                  more
                </Footnote>{' '}
                by listing it unoccupied and potentially staged.
              </Paragraph>
            </Box>
          </Accordion>

          <Accordion
            {...getProps(
              'How do you determine how much equity I can unlock from my current home?'
            )}
          >
            <Paragraph color="bbysBlue">
              The amount of equity you can unlock is determined using
              HomeLight’s proprietary algorithm, which takes into consideration
              an estimation of the home’s value, current market conditions,
              projected market risk, borrower financials, outstanding loans, and
              more. You’ll be able to unlock a portion of the overall equity you
              have in your home upfront, prior to selling the home — and will
              receive the remainder of your equity after the home sells, less
              program fees.
            </Paragraph>
          </Accordion>

          <Accordion
            {...getProps('What can I use my Equity Unlock Amount for? ')}
          >
            <Box flexDirection="column">
              <Paragraph mb="12px" color="bbysBlue">
                You can use your Equity Unlock Amount for more than a down
                payment on your new home. You can also use it for:
              </Paragraph>
              <IndentedText {...indentProps}>Moving expenses</IndentedText>
              <IndentedText {...indentProps}>Closing expenses</IndentedText>
              <IndentedText {...indentProps}>Property repair</IndentedText>
              <IndentedText {...indentProps}>And more</IndentedText>
            </Box>
          </Accordion>

          <Accordion {...getProps('What if my home is already listed?')}>
            <Paragraph color="bbysBlue">
              Not a problem! We consider all properties for the program
              regardless of days on the market. However, the total number of
              listed days can affect your Equity Unlock Amount based on the risk
              profile of the property.
            </Paragraph>
          </Accordion>

          <Accordion {...getProps('What if my current home doesn’t sell?')}>
            <Paragraph color="bbysBlue">
              I will work closely with you to sell your home. If your home does
              not sell within 90 days, HomeLight will buy it and work with me to
              list and sell it. You'll receive any profit from the sale, after
              deducting HomeLight's incurred costs.
            </Paragraph>
          </Accordion>

          <Accordion
            {...getProps('Sounds too good to be true. What’s the catch?')}
          >
            <Paragraph color="bbysBlue">
              There is no catch! You are paying to use the program but the
              savings and gains created can outweigh the cost of the program.
            </Paragraph>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};
