import styled, { css } from 'styled-components';

import { PropsWithChildren } from 'react';

import { Box } from '@hl-portals/ui';

import { useIntersectionObserver } from '@hl-portals/hooks';

const ExpandBox = styled(Box)<{ expand?: boolean }>`
  ${({ expand }) => css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%) scale(${expand ? '1' : '0'});
    transform-origin: top center;
    transition: all 400ms ease-in-out;
    opacity: ${expand ? '1' : '0'};
    border-radius: ${expand ? '0' : '46px'};
  `}
`;

type MagicSectionProps = {
  bg: string;
  title: React.ReactNode;
};

export const MagicSection = ({
  children,
  bg,
  title,
}: PropsWithChildren<MagicSectionProps>) => {
  const { ref, isIntersecting } = useIntersectionObserver<HTMLDivElement>({
    rootMargin: '-300px 0px',
  });

  return (
    <Box
      width="100%"
      flexDirection="column"
      justifyContent="center"
      position="relative"
      pb="80px"
      mb={{ xs: '20px', md: '80px' }}
      ref={ref}
      bgcolor={isIntersecting ? bg : 'white'}
      transition="all 500ms ease-in-out"
    >
      <Box flexDirection="column" zIndex={1}>
        <Box
          mx={isIntersecting ? '0' : { xs: '16px', md: '80px' }}
          p={
            isIntersecting
              ? { xs: '40px 16px', md: '80px' }
              : { xs: '40px 24px', md: '80px' }
          }
          bgcolor={bg}
          borderRadius={isIntersecting ? '0' : { xs: '20px', sm: '46px' }}
          mb={{ xs: '0', sm: '32px' }}
          transition="all 500ms ease-in-out"
        >
          {title}
        </Box>
        <Box mx={{ xs: '16px', md: '80px' }}>{children}</Box>
      </Box>
      <ExpandBox expand={isIntersecting} bgcolor={bg} zIndex={0} />
    </Box>
  );
};
