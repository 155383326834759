
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/agents/[agentSlug]",
      function () {
        return require("private-next-pages/agents/[agentSlug].tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/agents/[agentSlug]"])
      });
    }
  