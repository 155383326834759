import { Box, Button, Paragraph } from '@hl-portals/ui';

export const AgentBanner = () => (
  <Box bgcolor="#273653" justifyContent="center">
    <Box
      width="100%"
      p={{ xs: '16px', md: '12px 80px' }}
      justifyContent="space-between"
      alignItems="center"
      gap="8px"
    >
      <Paragraph variant="text-small" color="white">
        To edit or add your personal information go to Edit profile. This banner
        is not visible for clients.
      </Paragraph>
      <Button color="secondary-inverted" href="/settings" target="_blank">
        Edit Profile Information
      </Button>
    </Box>
  </Box>
);
