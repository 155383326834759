import styled from 'styled-components';

import { PropsWithChildren, useContext, useEffect, useRef } from 'react';

import { Box, Button, Paragraph, YouTubePlayer } from '@hl-portals/ui';

import { media } from '@hl-portals/helpers';

import { useModal, usePublicEvent, useScreenSize } from '@hl-portals/hooks';

import { AgentPageContext } from '../../../../contexts/lead-capture/v2';
import { CheckCircle } from '../check-circle';

const PlayIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C7.58065 4 4 7.58065 4 12C4 16.4194 7.58065 20 12 20C16.4194 20 20 16.4194 20 12C20 7.58065 16.4194 4 12 4Z"
      fill="#46B6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7324 12.7742L10.0549 16.0323C9.54526 16.3161 8.90332 15.9516 8.90332 15.3549V8.64518C8.90332 8.05163 9.54203 7.68389 10.0549 7.96776L15.7324 11.4194C16.2614 11.7161 16.2614 12.4807 15.7324 12.7742Z"
      fill="white"
    />
  </svg>
);

const LineOne = styled(Box)`
  width: 300px;
  height: 40px;
  margin-left: 80px;
  position: relative;
  border-top-right-radius: 200px;
  border-bottom-right-radius: 200px;
  background-color: #d0ecff;
  z-index: 1;

  &::after {
    content: '';
    width: 300px;
    height: 36px;
    position: absolute;
    top: 2px;
    right: 2px;
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
    background-color: white;
  }

  ${media.untilTablet`
    width: 240px;
    height: 30px;
    margin-left: 64px;

    &::after {
      width: 240px;
      height: 26px;
    }
  `}
`;

const TextContainer = styled(Box)`
  max-width: 800px;
  margin-top: -2px;
  padding: 24px;
  position: relative;
  border-top: 2px solid #d0ecff;
  border-bottom: 2px solid #d0ecff;
  border-left: 2px solid #d0ecff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

  &::after {
    content: '';
    width: calc(100% - 300px);
    height: 2px;
    position: absolute;
    top: -2px;
    right: 0px;
    background-color: white;
  }

  ${media.untilTablet`
    &::before {
      content: '';
      width: 50px;
      height: 2px;
      position: absolute;
      bottom: -2px;
      right: 0px;
      background-color: white;
    }

    &::after {
      width: calc(100% - 240px);
    }
  `}

  ${media.large`
    max-width: calc(100% - 360px);
  `}
`;

const BulletsContainer = styled(Box)`
  max-width: 800px;
  padding: 28px 28px 40px 0px;
  flex-direction: column;
  position: relative;
  border-right: 2px solid #d0ecff;

  ${media.untilTablet`
    margin-top: -2px;
    padding-bottom: 220px;
    border-bottom: 2px solid #d0ecff;
    border-top: 2px solid #d0ecff;
    border-radius: 20px;

    &::before {
      content: '';
      width: 20px;
      height: 12px;
      position: absolute;
      top: -1px;
      left: 0px;
      background-color: white;
      transform: rotate(3.8deg);
    }

    &::after {
      content: '';
      width: 50px;
      height: 16px;
      position: absolute;
      bottom: -2px;
      left: 0px;
      background-color: white;
    }
  `}

  ${media.large`
    max-width: calc(100% - 333px);
  `}
`;

const BulletItem = ({ children }: PropsWithChildren) => (
  <Box alignItems="center" gap="8px">
    <CheckCircle flex="0 0 20px" />
    <Paragraph variant="text-semi-bold" color="bbysBlue">
      {children}
    </Paragraph>
  </Box>
);

const AgentSectionContainer = styled(Box)`
  margin-top: -2px;
  margin-left: 17px;
  padding: 32px;
  padding-left: 15px;
  justify-content: center;
  align-items: center;
  position: relative;
  border-top: 2px solid #d0ecff;
  border-left: 2px solid #d0ecff;
  border-radius: 20px;
  border-bottom-left-radius: 0;

  &::before {
    content: '';
    width: 20px;
    height: 12px;
    position: absolute;
    top: -1px;
    right: 0px;
    background-color: white;
    transform: rotate(-3.8deg);
  }
`;

const AgentImage = styled(Box)<{ src: string }>`
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-image: ${({ src }) => `url(${src})`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: #eef0f6;
`;

const BrokerageLogo = styled.img`
  max-width: 100px;
  max-height: 60px;
`;

const MediaContainer = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;

  &:where(:not(:has(.photo)), :not(:has(.logo))) .divisor {
    display: none;
  }
`;

const AgentSection = () => {
  const logoRef = useRef<HTMLImageElement>(null);
  const { data } = useContext(AgentPageContext);
  const { isMobile } = useScreenSize();

  const { authorized_picture_url, broker_image_url, office_name_to_display } =
    data;

  useEffect(() => {
    if (broker_image_url && logoRef.current && isMobile) {
      const logo = logoRef.current;
      const width = logo.clientWidth;
      const maxWidth = 60;

      if (width > maxWidth) {
        logo.style.marginLeft = `-${width - maxWidth}px`;
      } else {
        logo.style.marginLeft = `${maxWidth - width}px`;
      }
    }
  }, [broker_image_url, isMobile]);

  return (
    <Box
      flexDirection={{ xs: 'column', sm: 'row' }}
      alignItems="center"
      gap="12px"
      mb="40px"
    >
      <MediaContainer>
        {broker_image_url && (
          <BrokerageLogo
            className="logo"
            ref={logoRef}
            src={broker_image_url}
          />
        )}
        <Box className="divisor" width="1px" height="60px" bgcolor="#DBDFE6" />
        {authorized_picture_url && (
          <AgentImage className="photo" src={authorized_picture_url} />
        )}
      </MediaContainer>

      <Box flexDirection="column" gap="4px">
        <Paragraph
          variant="text-bold"
          textAlign={{ xs: 'center', sm: 'left' }}
          color="bbysBlue"
        >
          {data.full_name}
        </Paragraph>
        {office_name_to_display && (
          <Paragraph textAlign={{ xs: 'center', sm: 'left' }} color="bbysBlue">
            Real Estate Agent with {office_name_to_display}
          </Paragraph>
        )}
      </Box>
    </Box>
  );
};

type YoutubeModalProps = {
  uuid: string;
  slug: string;
};

const YoutubeModal = ({ uuid, slug }: YoutubeModalProps) => {
  const { recordPublicEvent } = usePublicEvent();

  return (
    <Box
      width="900px"
      maxWidth="100%"
      height="500px"
      justifyContent="center"
      pt={{ xs: '0', md: '64px' }}
    >
      <YouTubePlayer
        videoId="Kg9IaIHZ6Mo"
        containerProps={{
          my: { xs: '64px', md: '0' },
        }}
        onPlay={() =>
          recordPublicEvent({
            event_action: 'video-play',
            source: 'bbys_self_service_express_approval',
            event_payload: {
              timestamp: new Date().toISOString(),
              agent_slug: slug,
              uuid,
            },
          })
        }
      />
    </Box>
  );
};

const HowItWorksBtn = styled(Box)`
  padding: 12px 24px;
  align-self: flex-start;
  height: 48px;
  border: 1px solid #c5c8cd;
  border-radius: 6px;
  background-color: white;
  cursor: pointer;
  transition: all 100ms ease-in-out;

  &:hover {
    background-color: #f8f8fb;
  }
`;

export const Hero = () => {
  const { uuid, slug, link } = useContext(AgentPageContext);
  const { isMobile } = useScreenSize();
  const { openModal } = useModal();

  const titleSize = isMobile ? 'heading-2' : 'heading-0';

  return (
    <Box
      maxWidth="100%"
      overflowX="hidden"
      p={{ xs: '16px 16px 0 16px', sm: '40px 80px 0px 80px' }}
      flexDirection="column"
      position="relative"
    >
      <LineOne />

      <TextContainer>
        <Paragraph variant={titleSize} color="bbysBlue">
          For such an important life event, <br />
          <Paragraph as="span" variant={titleSize} color="electricBlue">
            Buy Before You Sell
          </Paragraph>{' '}
          makes it easy
        </Paragraph>
      </TextContainer>

      <BulletsContainer>
        <Box
          width={{ xs: '32px', sm: 'auto' }}
          position="absolute"
          top={{ xs: 'unset', sm: '-24px' }}
          right="-24px"
          bottom={{ xs: '192px', sm: 'unset' }}
          left={{ xs: '103px', sm: 'unset' }}
        >
          <img src="/illustrations/heart.svg" />
        </Box>
        <Box
          position="absolute"
          top={{ xs: 'unset', sm: '32px' }}
          right="-334px"
          bottom={{ xs: '20px', sm: 'unset' }}
          left={{ xs: '16px', sm: 'unset' }}
        >
          <img src="/illustrations/big-house.svg" />
        </Box>

        <Box flexDirection="column" gap="24px" mb="40px">
          <Box flexDirection="column" gap="16px">
            <BulletItem>
              Buy your new home before selling your old one.
            </BulletItem>
            <BulletItem>
              Move one time and avoid paying for temporary housing.
            </BulletItem>
            <BulletItem>
              Skip the hassle of showing your home while you, your family or
              pets are still living in it.
            </BulletItem>
          </Box>

          <Box flexDirection={{ xs: 'column', sm: 'row' }} gap="10px">
            <Button as="a" size="large" href={link}>
              Apply Now
            </Button>

            <HowItWorksBtn
              width={{ xs: '100%', sm: 'auto' }}
              justifyContent="center"
              onClick={() =>
                openModal(<YoutubeModal uuid={uuid} slug={slug} />, {
                  enableBackDropClick: true,
                  enableCloseOnEsc: true,
                })
              }
            >
              <Paragraph
                as="span"
                mr="4px"
                variant="text-bold"
                color="bbysBlue"
              >
                How it works
              </Paragraph>
              <PlayIcon />
            </HowItWorksBtn>
          </Box>
        </Box>
        {!isMobile && <AgentSection />}
      </BulletsContainer>

      {isMobile && (
        <AgentSectionContainer>
          <AgentSection />
        </AgentSectionContainer>
      )}
    </Box>
  );
};
