import styled from 'styled-components';

import { Box, Paragraph } from '@hl-portals/ui';

import { useScreenSize } from '@hl-portals/hooks';

import { CheckCircle } from '../check-circle';

export const TestimonialWrapper = styled(Box)`
  background: linear-gradient(
      62deg,
      rgba(44, 54, 96, 0.94) 0%,
      rgba(44, 54, 96, 0.86) 52.57%,
      rgba(44, 54, 96, 0) 99.93%
    ),
    url(/images/lead-capture/testimonial-bg.png),
    lightgray 50% / cover no-repeat;
`;

export const Testimonial = () => {
  const { isMobile } = useScreenSize();
  const textSize = isMobile ? 'heading-4' : 'heading-1';

  return (
    <Box
      flexDirection="column"
      justifyContent="center"
      px={{ xs: '0', sm: '16px', md: '80px' }}
      mb="40px"
    >
      <Paragraph
        variant="heading-1"
        textAlign="center"
        mb="32px"
        color="bbysBlue"
      >
        Testimonial
      </Paragraph>
      <TestimonialWrapper
        width="100%"
        minHeight={{ xs: 'auto', sm: '522px' }}
        flexDirection="column"
        p={{ xs: '24px', sm: '80px' }}
        borderRadius={{ xs: '0', sm: '24px' }}
      >
        <Paragraph
          maxWidth="560px"
          mb="8px"
          variant={textSize}
          fontWeight="normal"
          color="white"
        >
          “ We not only had the advantage of moving into our new home before
          listing our old home, but also were able to{' '}
          <Paragraph as="span" variant={textSize} color="white">
            sell for more than expected.
          </Paragraph>{' '}
          ”
        </Paragraph>
        <Paragraph color="white" mb="8px" fontWeight="bold">
          Hunter W. and Angela B.
        </Paragraph>
        <Paragraph variant="text-small" color="white" mb="40px">
          Sunland, California
        </Paragraph>

        <Box alignItems="center" gap="8px">
          <CheckCircle />
          <Paragraph variant="light-title" color="white">
            Sold their home for{' '}
            <Paragraph
              as="span"
              variant="light-title"
              color="white"
              fontWeight="bold"
            >
              $159,000 over
            </Paragraph>{' '}
            asking price
          </Paragraph>
        </Box>
      </TestimonialWrapper>
    </Box>
  );
};
