import { useContext } from 'react';

import { Box, Button, Icon, Paragraph } from '@hl-portals/ui';

import { useScreenSize } from '@hl-portals/hooks';

import { AgentPageContext } from '../../../../contexts/lead-capture/v2';
import { ABTestCTAText } from '../ab-test-cta';

export const Header = () => {
  const { isMobile } = useScreenSize();
  const { link } = useContext(AgentPageContext);

  return (
    <Box
      justifyContent="space-between"
      alignItems="center"
      p={{ xs: '16px', md: '16px 80px' }}
      bgcolor="white"
      borderBottom="2px solid #EEF0F6"
      position="sticky"
      top="0"
      left="0"
      zIndex="2"
    >
      <Box
        flex="1"
        gap={{ xs: '0', sm: '8px' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
        flexWrap="wrap"
        justifyContent={{ xs: 'center', sm: 'flex-start' }}
        alignItems="center"
      >
        <Box gap="8px" alignItems="center">
          {!isMobile && (
            <Box p="4px" borderRadius="8px" bgcolor="#E9F6FF">
              <Icon type="tradeInSolid" size={20} fill="electricBlue" />
            </Box>
          )}
          <Paragraph
            variant={isMobile ? 'text-small-bold' : 'text-bold'}
            color="bbysBlue"
          >
            Buy Before You Sell
          </Paragraph>
        </Box>
      </Box>

      {!isMobile && (
        <Button as="a" size="large" href={link}>
          <ABTestCTAText />
        </Button>
      )}
    </Box>
  );
};
