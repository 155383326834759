import { PropsWithChildren } from 'react';

import { Box, Footnote, Icon, IconTypeProp, Paragraph } from '@hl-portals/ui';

import { useScreenSize } from '@hl-portals/hooks';

import { MagicSection } from '../magic-section';

type ItemProps = {
  label: string;
  icon: IconTypeProp;
};

const Item = ({ children, label, icon }: PropsWithChildren<ItemProps>) => {
  const { isMobile } = useScreenSize();

  return (
    <Box
      p="40px 24px"
      flexDirection="column"
      flex="1"
      alignItems="flex-start"
      gap="24px"
      bgcolor="white"
      borderRadius="24px"
    >
      <Box p="14px" bgcolor="#46B6FF" borderRadius="16px">
        <Icon type={icon} />
      </Box>
      <Paragraph
        variant={isMobile ? 'heading-4' : 'heading-3'}
        color="bbysBlue"
      >
        {label}
      </Paragraph>
      <Paragraph
        variant={isMobile ? 'heading-5' : 'heading-4'}
        fontWeight="normal"
        color="bbysBlue"
      >
        {children}
      </Paragraph>
    </Box>
  );
};

export const WhatMakesUsDifferent = () => {
  const { isMobile } = useScreenSize();

  const titleVariant = isMobile ? 'heading-2' : 'heading-xl';
  const footnoteProps = {
    variant: isMobile ? 'heading-5' : 'heading-4',
    fontWeight: 'normal',
  } as const;

  return (
    <MagicSection
      bg="#D0ECFF"
      title={
        <Paragraph variant={titleVariant} color="bbysBlue">
          What makes us
          <br />
          <Paragraph as="span" variant={titleVariant} color="electricBlue">
            different
          </Paragraph>
        </Paragraph>
      }
    >
      <Box flexDirection={{ xs: 'column', md: 'row' }} gap="24px">
        <Item label="Unlock Equity" icon="lock">
          Unlock up to $2 million{' '}
          <Footnote note="4" {...footnoteProps}>
            dollars
          </Footnote>{' '}
          in equity from your current home to cover your down payment, moving,
          closing costs, repairs and more. It’s your choice!
        </Item>
        <Item label="Make a competitive offer" icon="offer">
          We'll remove your home sale{' '}
          <Footnote note="1" {...footnoteProps}>
            contingency
          </Footnote>
          so you can make a competitive offer and save money — as sellers often
          value certainty over nabbing top dollar.
        </Item>
        <Item label="Sell for up to 10% more" icon="graphicRise">
          After you move, list your current home unoccupied and potentially
          staged, which can lead to a higher selling{' '}
          <Footnote note="3" {...footnoteProps}>
            price
          </Footnote>
          .
        </Item>
      </Box>
    </MagicSection>
  );
};
