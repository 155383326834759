import { createFlagsmithInstance } from 'flagsmith';
import { v4 as uuidv4 } from 'uuid';

import { useEffect, useRef, useState } from 'react';

import { useSession } from 'next-auth/react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { ABTest, Box, Button } from '@hl-portals/ui';

import { localStorageKeys as keys } from '@hl-portals/constants';

import { config } from '@hl-portals/helpers';

import { usePublicEvent, useScreenSize } from '@hl-portals/hooks';

import {
  EquityCalculatorModalWrapperStyles,
  EquityCalculatorSection,
  FAQ,
  Footer,
  Header,
  Hero,
  Steps,
  StickyContainer,
  Testimonial,
  WhatMakesUsDifferent,
} from '../../components/lead-capture/v2';
import { ABTestCTAText } from '../../components/lead-capture/v2/ab-test-cta';
import { AgentBanner } from '../../components/lead-capture/v2/agent-banner';
import { AgentPageContext } from '../../contexts/lead-capture/v2';
import { AB_TEST_CTA } from '../../metadata/constants/lead-capture';
import { AgentPageProps } from '../../metadata/types';

export const AgentPageV2 = ({ data }: AgentPageProps) => {
  const flagsmithInstance = useRef(createFlagsmithInstance());
  const [uuid, setUUID] = useState('');

  const router = useRouter();
  const { isMobile } = useScreenSize();
  const session = useSession();
  const { recordPublicEvent } = usePublicEvent();

  const slug = router.query.agentSlug as string;
  const link = `${config.clientAppUrl}/express-approval?localuuid=${uuid}&slug=${slug}`;
  const isSessionLoading = session.status === 'loading';
  const isAuthenticated = session.status === 'authenticated';

  useEffect(() => {
    const localUUID =
      localStorage.getItem(keys.SELF_SERVICE_LOCAL_UUID) || uuidv4();

    setUUID(localUUID);
    localStorage.setItem(keys.SELF_SERVICE_LOCAL_UUID, localUUID);
  }, []);

  useEffect(() => {
    if (uuid) {
      recordPublicEvent({
        event_action: 'page_view',
        source: 'bbys_self_service_express_approval',
        event_payload: {
          agent_slug: slug,
          uuid,
        },
      });
    }
  }, [uuid]);

  useEffect(() => {
    if (!isSessionLoading && !isAuthenticated && uuid) {
      flagsmithInstance.current.identify(uuid);
    }
  }, [uuid, isSessionLoading, isAuthenticated, session]);

  return (
    <ABTest.Root flag="ab-testing_lead-capture-v2-cta-text">
      <AgentPageContext.Provider value={{ data, link, slug, uuid }}>
        <Head>
          <meta
            property="og:image"
            content="/images/lead-capture/bbys-link-preview.png"
          />
          <meta
            property="twitter:image"
            content="/images/lead-capture/twitter-bbys-link-preview.png"
          />
          <meta property="og:title" content="Buy Before You Sell" />
          <meta property="og:type" content="website" />
        </Head>
        <EquityCalculatorModalWrapperStyles />
        {session.data && <AgentBanner />}
        <Box
          width="100%"
          maxWidth="1800px"
          margin="0 auto"
          minHeight="100vh"
          flexDirection="column"
          bgcolor="white"
          position="relative"
        >
          <Header />
          <Hero />
          <WhatMakesUsDifferent />
          <FAQ />
          <Steps />
          <Testimonial />
          {!isMobile && <EquityCalculatorSection />}
          <Footer />
          {isMobile && (
            <StickyContainer>
              <Button
                as="a"
                size="large"
                flex="1"
                href={`${link}&source=lead_capture_page`}
              >
                <ABTestCTAText />
              </Button>
            </StickyContainer>
          )}
        </Box>
      </AgentPageContext.Provider>
    </ABTest.Root>
  );
};
