import * as Sentry from '@sentry/nextjs';
import { useFlags } from 'flagsmith/react';
import Jsona from 'jsona';

import { useMemo } from 'react';

import { GetServerSideProps } from 'next';

import { config } from '@hl-portals/helpers';

import { AgentPageProps, AgentPageV1, AgentPageV2 } from '../../modules/agent';

export default function AgentPage({ data }: AgentPageProps) {
  const {
    'client-lead-capture-fe-v2': { enabled, value },
  } = useFlags(['client-lead-capture-fe-v2']);

  const v2Enabled = useMemo(() => {
    const { agent_ids } = value
      ? JSON.parse(value.toString())
      : { agent_ids: [] };

    const agentId = data.id;
    return enabled || agent_ids.includes(+agentId);
  }, [data.id, enabled, value]);

  if (v2Enabled) return <AgentPageV2 data={data} />;
  return <AgentPageV1 data={data} />;
}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { params, query } = ctx;

  const agentSlug = params.agentSlug;
  const utmSource = query['utm_source'];
  const dataFormatter = new Jsona();

  const utmSourceParam = `utm_source=${utmSource}`;
  const includesParam =
    'include=user,brokerage_brand,agent_certifications,agent_awards,languages';

  const queryParams = utmSource
    ? `?${utmSourceParam}&${includesParam}`
    : `?${includesParam}`;

  const url = `${config.hapiHost}/api/agent-data-service/client-page/agents/${agentSlug}${queryParams}`;

  const FAILURE_RETURN = {
    redirect: {
      permanent: false,
      destination: '/404',
    },
    props: {},
  };

  try {
    const res = await fetch(url);
    const data = await res.json();

    if (!data?.data) return FAILURE_RETURN;

    return {
      props: {
        data: dataFormatter.deserialize(data),
      },
    };
  } catch (error) {
    Sentry.captureException(error);
    return FAILURE_RETURN;
  }
};
