import { zodResolver } from '@hookform/resolvers/zod';
import { createGlobalStyle } from 'styled-components';

import {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm, useFormContext } from 'react-hook-form';
import { toast } from 'react-toastify';

import {
  Alert,
  Box,
  Button,
  FadeIn,
  Form,
  Icon,
  Inputs,
  Paragraph,
} from '@hl-portals/ui';

import { formatCurrency, media } from '@hl-portals/helpers';

import {
  CALCULATE_EQUITY_ERROR_MESSAGES,
  CalculateEquityData,
  useCalculateClientEquity,
  usePublicEvent,
  useScreenSize,
} from '@hl-portals/hooks';

import { Loading } from '../../../../../../components/ui/EquityCalculator';
import { usePropertyEligibilityCheck } from '../../../../../../hooks/usePropertyEligibilityCheck/index';
import { usePropertyUUID } from '../../../../../../hooks/usePropertyUUID/index';
import { AgentPageContext } from '../../../../contexts/lead-capture/v2';
import {
  EUCForm,
  schema,
} from '../../../../metadata/forms/equity-calculator-form';
import { ABTestCTAText } from '../ab-test-cta';
import { StickyContainer } from '../sticky-container';

// =================================================================
// Property Form

type PropertyFormProps = {
  mortgageError?: string;
  setTypedOnlyAddress: Dispatch<SetStateAction<boolean>>;
};

const PropertyForm = ({
  mortgageError,
  setTypedOnlyAddress,
}: PropertyFormProps) => {
  const { isMobile } = useScreenSize();
  const { setValue } = useFormContext();

  return (
    <>
      <FadeIn
        flexDirection="column"
        gap="24px"
        flex="1"
        p={{ xs: '20px 16px 40px 16px', sm: '0' }}
      >
        <Box
          gap="16px"
          flexDirection={{ xs: 'column', lg: 'row' }}
          alignItems={{ xs: 'unset', lg: 'flex-end' }}
        >
          <Box flex="1">
            <Inputs.Address
              name="fullAddress"
              placeholder="Enter property address"
              label="What is your current home address?"
              labelProps={{ color: 'bbysBlue' as any }}
              testId="input-address"
              onKeyUp={() => setTypedOnlyAddress(true)}
              onEnter={({
                hl_full_address,
                city,
                state_or_province,
                postal_code,
                full_address,
              }) => {
                setTypedOnlyAddress(false);
                setValue('fullAddress', full_address, { shouldDirty: true });
                setValue('address', hl_full_address);
                setValue('city', city);
                setValue('postalCode', postal_code);
                setValue('state', state_or_province);
              }}
            />
          </Box>
          <Inputs.Text
            name="unit"
            label="Unit / Apt / Suite"
            labelProps={{ color: 'bbysBlue' as any }}
            optional
            placeholder="Enter unit"
            testId="input-unit"
          />
        </Box>
        <Inputs.Currency
          name="value"
          label="How much is your home worth?"
          labelProps={{ color: 'bbysBlue' as any }}
          placeholder="$000,000"
          testId="input-value"
        />
        <Inputs.Currency
          name="mortgage"
          label="What is your Mortgage Balance?"
          labelProps={{ color: 'bbysBlue' as any }}
          placeholder="$000,000"
          testId="input-mortgage"
          optional
        />
        {mortgageError && (
          <Alert variant="warning" mt="-12px" mb="8px">
            {mortgageError}
          </Alert>
        )}
        <Inputs.Text
          name="name"
          label="Name"
          labelProps={{ color: 'bbysBlue' as any }}
          placeholder="Enter your name"
          testId="input-name"
        />
        <Inputs.Email
          name="email"
          label="Email Address"
          labelProps={{ color: 'bbysBlue' as any }}
          placeholder="Enter your email address"
          testId="input-email"
        />

        {!isMobile && (
          <Button type="submit" size="large" data-test="continue-btn">
            Estimate Equity Unlock Amount
          </Button>
        )}
      </FadeIn>

      {isMobile && (
        <StickyContainer>
          <Button type="submit" size="large" data-test="continue-btn" flex="1">
            Estimate Equity Unlock Amount
          </Button>
        </StickyContainer>
      )}
    </>
  );
};

// =================================================================
// Equity Calculator Title

const EquityCalculatorTitle = () => (
  <Box flexDirection="column" gap="10px" mb="40px">
    <Paragraph variant="heading-4" fontWeight="normal" color="bbysBlue">
      Equity Unlock Calculator
    </Paragraph>
    <Box width="126px" height="2px" borderRadius="200px" bgcolor="#8BD1FF" />
  </Box>
);

// =================================================================
// Result

const ResultRangeLabel = ({ children }: PropsWithChildren) => (
  <Paragraph
    variant="text-xsmall"
    textTransform="uppercase"
    letterSpacing="1px"
  >
    {children}
  </Paragraph>
);

type ResultProps = {
  data: CalculateEquityData;
  link: string;
  onGoBack: () => void;
};

const Result = ({ data, onGoBack, link }: ResultProps) => {
  const { isMobile, isTablet } = useScreenSize();
  const { low_end_range, high_end_range } = data;

  return (
    <FadeIn flexDirection="column" p={{ xs: '16px', sm: '0' }}>
      <EquityCalculatorTitle />

      <Box
        gap={{ xs: '24px', md: '80px' }}
        mb={{ xs: '24px', md: '64px' }}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Box
          flex="1"
          maxWidth="400px"
          flexDirection="column"
          gap={{ xs: '16px', md: '40px' }}
        >
          <Box
            onClick={onGoBack}
            alignItems="center"
            alignSelf="flex-start"
            cursor="pointer"
          >
            <Icon size={16} type="angleLeft" />
            <Paragraph variant="text-small">Back to Calculator</Paragraph>
          </Box>

          <Paragraph
            variant={isMobile || isTablet ? 'heading-4' : 'heading-1'}
            fontWeight="normal"
          >
            Your estimated Equity Unlock Amount:
          </Paragraph>
        </Box>

        <Box
          flex={{ xs: 'unset', md: '1' }}
          p="40px"
          alignItems="center"
          gap="20px"
          borderRadius="24px"
          bgcolor="white"
          boxShadow="0px 14px 48px 0px rgba(108, 129, 171, 0.20)"
        >
          <Box flexDirection="column" gap="8px">
            <ResultRangeLabel>from</ResultRangeLabel>
            <Paragraph variant="heading-2">
              {formatCurrency({
                value: low_end_range < 0 ? 0 : low_end_range,
              })}
            </Paragraph>
          </Box>

          <Box width="8px" height="4px" mt="20px" bgcolor="#46B6FF" />

          <Box flexDirection="column" gap="8px">
            <ResultRangeLabel>to</ResultRangeLabel>
            <Paragraph variant="heading-2">
              {formatCurrency({ value: high_end_range })}
            </Paragraph>
          </Box>
        </Box>
      </Box>

      <Box flexDirection="column" gap="24px" mb="48px">
        <Paragraph color="bbysBlue" variant="heading-4">
          Get approved in 24 hours or less — with no cost or commitment.
        </Paragraph>
        <Paragraph>
          Find out if your property is a good fit for the program and get your
          final equity unlock amount approved. No cost or commitment required.
        </Paragraph>

        <Button
          as="a"
          width={{ xs: '100%', sm: '300px' }}
          size="large"
          justifyContent="space-between"
          alignSelf={{ xs: 'unset', md: 'flex-start' }}
          href={`${link}&source=lead_capture_page_euc`}
        >
          <ABTestCTAText />
          <Icon type="arrowRight" fill="white" />
        </Button>
      </Box>

      <Paragraph variant="text-xsmall">
        This range is meant to be a guide/estimate of the equity unlock amount
        based off of your input and our system’s initial review of your
        property. This is not a commitment to lend and is not a formal approval.
        Final numbers will be provided after further review and additional
        information is gathered. Minimums may apply.
      </Paragraph>
    </FadeIn>
  );
};

// =================================================================
// Equity Calculator Loading

const EquityCalculatorLoading = () => (
  <FadeIn
    width="100%"
    height="100%"
    justifyContent="center"
    alignItems="center"
    position="absolute"
    top="0"
    right="0"
    bottom="0"
    left="0"
    bgcolor="white"
    zIndex="1"
  >
    <Loading />
  </FadeIn>
);

// =================================================================
// Equity Calculator

type EquityCalculatorProps = {
  link: string;
  uuid: string;
  slug: string;
};

const EquityCalculator = ({ link, uuid, slug }: EquityCalculatorProps) => {
  const [showResult, setShowResult] = useState(false);
  const [mortgageError, setMortgageError] = useState('');
  const [result, setResult] = useState<CalculateEquityData | null>(null);
  const [typedOnlyAddress, setTypedOnlyAddress] = useState(true);

  const { recordPublicEvent } = usePublicEvent();
  const { isMobile } = useScreenSize();
  const form = useForm<EUCForm>({ resolver: zodResolver(schema) });
  const formData = form.watch();

  const publicEventPayload = useMemo(
    () => ({
      source: 'bbys_self_service_express_approval',
      timestamp: new Date().toISOString(),
      clientName: formData.name,
      clientEmail: formData.email,
      propertyAddress: formData.fullAddress,
      estimatedPropertyValue: formData.value,
      estimatedMortgageBalance: formData.mortgage,
      associatedAgent: slug,
      uuid,
    }),
    [
      formData.name,
      formData.email,
      formData.fullAddress,
      formData.value,
      formData.mortgage,
      slug,
      uuid,
    ]
  );

  const { isFetching: isEligibilityLoading, refetch: checkEligibility } =
    usePropertyEligibilityCheck(formData.uuid, formData.state, {
      onSuccess: () => {
        setShowResult(true);
        recordPublicEvent({
          event_action: 'equity-calculation-success',
          source: 'bbys_self_service_express_approval',
          event_payload: {
            estimated_equity_unlock_range_min: result?.low_end_range,
            estimated_equity_unlock_range_max: result?.high_end_range,
            agent_slug: slug,
            ...publicEventPayload,
          },
        });
      },
      isPublic: true,
      enabled: false,
    });

  const { isFetching: isCalculationLoading } = useCalculateClientEquity(
    {
      ...formData,
      propertyUUID: formData.uuid,
      localUUID: uuid,
      slug,
      source: 'bbys_self_service_lead_capture',
    },
    {
      onSuccess: (data) => {
        setResult(data);
        checkEligibility();
      },
      onError: (error) => {
        if (error.response.data?.errors) {
          if (error.response.data?.errors?.hc_no_mortgage_data) {
            form.setError('fullAddress', {
              message: CALCULATE_EQUITY_ERROR_MESSAGES.NO_DATA,
            });
            return;
          }
          setMortgageError(CALCULATE_EQUITY_ERROR_MESSAGES.MORTGAGE);
          return;
        }
        toast('There was an error', { type: 'error' });
      },
      enabled: !!formData.uuid,
    }
  );

  const { isFetching: isUUIDLoading, refetch: fetchPropertyUUID } =
    usePropertyUUID(formData.fullAddress, {
      onSuccess: (propertyUUID) => {
        form.setValue('uuid', propertyUUID);
      },
      isPublic: true,
      enabled: false,
    });

  const onSubmit = () => {
    if (typedOnlyAddress) {
      form.setError('fullAddress', {
        type: 'required',
        message: 'Please select a suggested address',
      });
      return;
    }
    form.setValue('uuid', undefined);
    fetchPropertyUUID();
  };

  const isLoading =
    isUUIDLoading || isCalculationLoading || isEligibilityLoading;

  useEffect(() => {
    const fireExitEvent = () => {
      if (isLoading) {
        recordPublicEvent({
          event_action: 'equity-calculation-quit',
          source: 'bbys_self_service_express_approval',
          event_payload: {
            ...publicEventPayload,
          },
        });
      }
    };
    window.addEventListener('beforeunload', fireExitEvent);
    return () => window.removeEventListener('beforeunload', fireExitEvent);
  }, [isLoading, recordPublicEvent, publicEventPayload]);

  useEffect(() => {
    setMortgageError('');
  }, [formData.mortgage]);

  useEffect(() => {
    form.setValue('uuid', '');
  }, [form, formData.fullAddress, formData.value, formData.mortgage]);

  if (showResult) {
    return (
      <Result
        data={result}
        link={link}
        onGoBack={() => {
          form.setValue('uuid', '');
          setShowResult(false);
        }}
      />
    );
  }

  return (
    <>
      <FadeIn
        maxWidth={{ xs: '100%', md: '640px', lg: '500px' }}
        flexDirection="column"
        justifyContent="center"
        alignSelf={{ xs: 'unset', md: 'flex-start', lg: 'center' }}
        p={{ xs: '0 16px', sm: '0' }}
      >
        <EquityCalculatorTitle />
        <Paragraph
          variant={isMobile ? 'heading-4' : 'heading-1'}
          fontWeight="normal"
          color="bbysBlue"
        >
          Estimate how much equity we can unlock from your current home
        </Paragraph>
      </FadeIn>

      <FadeIn
        p={{ xs: '0', sm: '48px 32px' }}
        flex="1"
        width="100%"
        bgcolor="white"
        boxShadow={{
          xs: 'none',
          md: '0px 4px 20px 0px rgba(108, 129, 171, 0.20)',
        }}
        borderRadius={{ xs: '0', sm: '24px' }}
      >
        <Box flex="1" position="relative">
          {isLoading && <EquityCalculatorLoading />}
          <Form methods={form} onSubmit={onSubmit}>
            <PropertyForm
              mortgageError={mortgageError}
              setTypedOnlyAddress={setTypedOnlyAddress}
            />
          </Form>
        </Box>
      </FadeIn>
    </>
  );
};

// =================================================================
// Equity Calculator Section

export const EquityCalculatorSection = () => {
  const { link, uuid, slug } = useContext(AgentPageContext);

  return (
    <Box flexDirection="column" gap="64px" mb="24px">
      <Box
        minHeight={{ xs: 'auto', md: '782px' }}
        mx={{ xs: '16px', md: '80px' }}
        p={{ xs: '32px 16px', md: '32px 40px 56px 40px' }}
        flexDirection={{ sm: 'column', lg: 'row' }}
        gap={{ sm: '40px', lg: '80px' }}
        alignItems="center"
        borderRadius="24px"
        bgcolor="#E9F6FF"
        boxShadow="0px 14px 48px 0px rgba(108, 129, 171, 0.20)"
      >
        <EquityCalculator link={link} uuid={uuid} slug={slug} />
      </Box>
    </Box>
  );
};

// =================================================================
// Equity Calculator Modal

export const EquityCalculatorModalWrapperStyles = createGlobalStyle`
  #modal.euc-modal-wrapper {
    overflow: auto;
    background-color: #E9F6FF;

    ${media.untilTablet`
      min-height: "100%";
      padding: 80px 0 0 0;
    `}
  }
`;

export const EquityCalculatorModal = ({
  link,
  uuid,
  slug,
}: EquityCalculatorProps) => (
  <Box
    p={{ xs: '0', sm: '40px 40px 56px 40px' }}
    gap="20px"
    flexDirection={{ xs: 'column', md: 'row' }}
    bgcolor="#E9F6FF"
  >
    <EquityCalculator link={link} uuid={uuid} slug={slug} />
  </Box>
);
