import { createContext } from 'react';

import { AgentPageProps } from '../../../metadata/types';

type AgentPageContextState = {
  data: AgentPageProps['data'];
  link: string;
  slug: string;
  uuid: string;
};

export const AgentPageContext = createContext<AgentPageContextState | null>(
  null
);
