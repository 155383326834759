import { ABTest } from '@hl-portals/ui';

import { AB_TEST_CTA } from '../../../../metadata/constants/lead-capture';

export const ABTestCTAText = () => {
  return (
    <>
      <ABTest.Subject name={AB_TEST_CTA.GET_APPROVED}>
        Get Approved
      </ABTest.Subject>
      <ABTest.Subject name={AB_TEST_CTA.GET_STARTED}>
        Get Started
      </ABTest.Subject>
      <ABTest.Fallback>Get Approved</ABTest.Fallback>
    </>
  );
};
