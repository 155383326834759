import styled from 'styled-components';

import { PropsWithChildren, useContext } from 'react';

import { Box, Button, Icon, Paragraph } from '@hl-portals/ui';

import { media } from '@hl-portals/helpers';

import { useModal, useScreenSize } from '@hl-portals/hooks';

import { AgentPageContext } from '../../../../contexts/lead-capture/v2';
import { ABTestCTAText } from '../ab-test-cta';
import { EquityCalculatorModal } from '../equity-calculator';
import { MagicSection } from '../magic-section';

const StepLabel = styled(Paragraph)`
  position: relative;
  color: #46b6ff !important;

  &::after {
    content: '';
    width: 100%;
    height: 4px;
    position: absolute;
    top: 40px;
    left: 0;
    border-radius: 200px;
    background-color: #46b6ff;
  }

  ${media.untilTablet`
    &::after {
      display: none;
    }
  `}
`;

const StepImage = styled(Box)<{ src: string }>`
  ${({ src }) => `
    width: 100%;
    flex: 0 0 520px;
    height: 520px;
    border-radius: 24px;
    box-shadow: 0px 2px 4px 0px rgba(108, 129, 171, 0.30);
    background-image: url(${src});
    background-size: cover;
    background-position: center center;
  `}
`;

type StepProps = {
  index: number;
  label: React.ReactNode;
  imgSrc: string;
  direction: 'ltr' | 'rtl';
};

const Step = ({
  children,
  index,
  label,
  imgSrc,
  direction = 'ltr',
}: PropsWithChildren<StepProps>) => (
  <Box
    gap={{ xs: '16px', sm: '56px' }}
    flexDirection={{ xs: 'column', md: 'row' }}
    alignItems="center"
  >
    <Box order={{ xs: '1', md: direction === 'ltr' ? '0' : '1' }}>
      <Paragraph mr="16px" variant="heading-3" color="electricBlue">
        {index}
      </Paragraph>
      <Box
        flexDirection="column"
        alignItems="flex-start"
        gap={{ xs: '16px', sm: '36px' }}
      >
        {label}
        {children}
      </Box>
    </Box>
    <StepImage
      order={{ xs: '0', md: direction === 'ltr' ? '1' : '0' }}
      src={imgSrc}
    />
  </Box>
);

const EUCButton = styled(Box)`
  padding: 8px 16px;
  gap: 12px;
  border: 1px solid #dbdfe6;
  border-radius: 16px;
  background-color: white;
  align-items: center;
  cursor: pointer;

  .calculator-icon-hover {
    display: none;
  }

  &:hover {
    border: 1px solid #46b6ff;
    background-color: #e9f6ff;

    .calculator-icon-idle {
      display: none;
    }

    .calculator-icon-hover {
      display: block;
    }
  }
`;

export const Steps = () => {
  const { openModal } = useModal();
  const { isMobile } = useScreenSize();
  const { link, uuid, slug } = useContext(AgentPageContext);

  const headingSize = isMobile ? 'heading-2' : 'heading-1';
  const labelSize = isMobile ? 'heading-3' : 'heading-3.5';

  const textProps = {
    variant: isMobile ? 'text' : 'paragraph-big',
    lineHeight: isMobile ? '28.8px' : '36px',
    color: 'bbysBlue',
  } as const;

  return (
    <MagicSection
      bg="#EEF0F6"
      title={
        <Paragraph variant={headingSize} color="bbysBlue">
          A three-step process that{!isMobile && <br />} puts{' '}
          <Paragraph as="span" variant={headingSize} color="electricBlue">
            you in control
          </Paragraph>
        </Paragraph>
      }
    >
      <Box
        maxWidth="100%"
        flexDirection="column"
        gap="80px"
        px={{ xs: '0', lg: '80px' }}
      >
        <Step
          index={1}
          direction="ltr"
          imgSrc="/images/lead-capture/step-1.png"
          label={
            <Paragraph variant={labelSize} color="bbysBlue">
              <StepLabel as="span" variant={labelSize}>
                Apply
              </StepLabel>{' '}
              in minutes with no commitment
            </Paragraph>
          }
        >
          <Paragraph {...textProps}>
            Find out if your property is a good fit for the program and get your
            equity unlock amount approved in 24 hours or less. No cost or
            commitment required.
          </Paragraph>
          <Button
            as="a"
            size="large"
            width={{ xs: '100%', sm: 'auto' }}
            justifyContent="space-between"
            href={link}
          >
            <ABTestCTAText />
            {isMobile && <Icon type="arrowRight" fill="white" />}
          </Button>
        </Step>

        <Step
          index={2}
          direction="rtl"
          imgSrc="/images/lead-capture/step-2.jpeg"
          label={
            <Paragraph variant={labelSize} color="bbysBlue">
              <StepLabel as="span" variant={labelSize}>
                Buy
              </StepLabel>{' '}
              your dream home
            </Paragraph>
          }
        >
          <Paragraph {...textProps}>
            Once you're approved, you'll have access to a portion of your equity
            in your current home. You’ll be able to submit a competitive offer
            with no home sale contingency at any time — regardless of how long
            it takes to find your dream home.
          </Paragraph>

          <EUCButton
            onClick={() =>
              openModal(
                <EquityCalculatorModal link={link} uuid={uuid} slug={slug} />,
                {
                  wrapperClassName: 'euc-modal-wrapper',
                }
              )
            }
          >
            <Box
              className="calculator-icon-idle"
              bgcolor="#F2F9FE"
              borderRadius="50%"
              p="8px"
            >
              <Icon
                className="calculator-solid-blue"
                type="calculatorSolid"
                fill="electricBlue"
              />
            </Box>

            <Box
              className="calculator-icon-hover"
              bgcolor="#8BD1FF"
              borderRadius="50%"
              p="8px"
            >
              <Icon
                className="calculator-solid-white"
                type="calculatorSolid"
                fill="white"
              />
            </Box>
            <Paragraph variant="text-small" color="aaBlue">
              Find out how much equity we can unlock for you.
            </Paragraph>
            <Icon type="arrowLongRight" fill="electricBlue" />
          </EUCButton>
        </Step>

        <Step
          index={3}
          direction="ltr"
          imgSrc="/images/lead-capture/step-3.jpeg"
          label={
            <Paragraph variant={labelSize} color="bbysBlue">
              <StepLabel as="span" variant={labelSize}>
                Sell
              </StepLabel>{' '}
              with peace of mind
            </Paragraph>
          }
        >
          <Paragraph {...textProps}>
            After you move into your new home, we will list your unoccupied home
            on the market to attract the strongest offer possible. You'll
            receive the remainder of your equity after the home sells.
          </Paragraph>
        </Step>
      </Box>
    </MagicSection>
  );
};
